import React from "react";
import { Container } from 'react-bootstrap';

const HomeBanner = () => {

  return (
    <Container>
      <div className="home-banner rounded h-100 shadow-sm">
        
      </div>
   </Container> 
  )
}

export default HomeBanner;
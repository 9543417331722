import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

export default class Contact extends React.Component {
  

  componentDidMount() {
    
  }

  render() {
    return (
      <Container className="wrapper-inside container-fluid min-vh-100">
        <Container className="wrapper-inside">
            
        </Container>

        <Container >
            <div className="p-5 mb-4 bg-light rounded-3" style={{background: 'transparent', backgroundImage: 'url("../../media/cars3.jpeg")', }}>
              <Container>
                <div className="text-shadow">
                <h1 className="display-5 fw-bold jumbo-text">Business 83 Auto Sales </h1>
                <p className="col-md-8 fs-4 jumbo-text">Used Auto Cars Serving The Rio Grande Valley of South Texas</p>
                </div>
              </Container>
            </div>
        </Container>

        <Container>
          <Row>
            <div className="d-flex justify-content-center">
              <Col md={8}>
                <h1> Contact Us</h1>
                <h5>If you are looking for a new used vehicle please feel free to swing by 
                our show room and see for yourself all the amazing deals we have. We re-stock our 
                inventory every week so check back here regularly to see the latest additions
                to our stock. If you have any questions please feel free to call, text or email
                us</h5>

                <h3>Mission, Texas Office:</h3>
                <h5>7523 W. Hwy Business 83 Mission, Texas 78572</h5>
              </Col>
            </div>
          </Row>

            <div className="d-flex h-50 justify-content-center" md={8} lg={6}>
              
                <img src='../../media/contact.png' className="img-fluid contact-image " alt="Contact Information for Business 83 Auto Sales"/>
              
            </div>
        </Container>

        <Container>
          <Row>
            <Footer/>
          </Row>
        </Container>
      </Container>
    )
  }
}
import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';



import Footer from './Footer';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function compileDescription(parts){
  let desc = "";
  parts.forEach(part=>{
    desc = desc + " " + part;
  })
  desc.trim();
  return desc;
}

function pluck(array, key) {
  let result = [];
  for (let i = 0; i < array.length; i++){
    if(array[i].id === key){
    // console.log(array[i].id);
      result.push(array[i]);
    }
  }
  return result;
}

function pluckHomepage(array, key) {
  let result = [];
  for (let i = 0; i < array.length; i++){
    if(array[i].id === key){
      if(array[i].path.indexOf('1001')>1){
        result.push(array[i]);
      }
    }
  }
  return result;
}

function makeAdPage(state,vehicle){
  let result,results = {};
  for(let i =0; i< state.length; i++){
    if(state[i][0].id === vehicle){
      // console.log(state[i]);
      result = state[i];
    }
  }
  results = toState(result);
  return results;
}

function toState(arr){
  var result = [];
  var count = 1;
  arr.map(element=>{
    element.id = element.id+'-'+count;
    count++;
    result.push([element]);
  })
  return result;
}

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      vehicles: [],
      vehiclesHomepage: [],
      vehiclesDetails: [],
      currentAdImages: [],
      inAd: false,
    }
  }



  componentDidMount() {

    const compileVehicles = (vehicle) => {
      // console.log(this.state.vehicles);
      let currentState = this.state.vehicles;

      // adding current vehicle, description, image to copy of state
      currentState.push(vehicle);
      let newState = currentState.sort();
      
      // extract unique ids for each vehicles in state array
      let uniqueIds = [...new Set(newState.map(veh => veh.id))];

      // create detailed tree with all images
      let vehicleDetails = uniqueIds.map(function(key,index){
        return pluck(newState,key);
      })
      // create home page array of items to display
      let vehiclesHomepage = uniqueIds.map(function(key,index){
        return pluckHomepage(newState,key);
      })

      //set home page images and paths for ads
      this.setState({'vehiclesHomepage': vehiclesHomepage});
      this.setState({'vehicleDetails': vehicleDetails})

    }

    // initial call to API for data
    if(this.state.loading){
      axios.get(`https://eljlpfyxc4.execute-api.us-east-1.amazonaws.com/production/`)
      .then(res => {
        let cars = {};
        res.data.Contents.map(function(key,index){
          // console.log(key.Key);
          // var vehicles1 = {};
          if(key.Key.indexOf('-')){
            //find vehicle id
            let pathParts = key.Key.split('-');
            let vehicle = pathParts[0];

            //get vehicle description
            pathParts.shift();
            pathParts.pop();
            let description = compileDescription(pathParts);

            // return individual car to the object
            cars = {id: vehicle, "description": description, 'path': 'https://b83-inventory.s3.amazonaws.com/'+key.Key};
          }
          compileVehicles(cars);
          return null;
        });
      })
    }
    
  }

  render() {

    const goToAd = (id) => {
      let result = makeAdPage(this.state.vehicleDetails,id[0].id);
      this.setState({'currentAdImages': result});
      this.setState({'inAd': true});
    }

    const goToInventory = () => {
      this.setState({'inAd': false});
    }

    const ReturnToInventory = () =>{
      if(this.state.inAd){
        return <div className="d-flex justify-content-center p-2">
          <div className="d-flex justify-content-center p-2">
            <h5>{this.state.currentAdImages[0].description}</h5>
          </div>
          <Button className="btn btn-primary p-2" onClick={() => goToInventory()}>Return to All Inventory</Button>
        </div>;
          
      }
      else return null;
    }

    const Display = () =>{
      if(this.state.inAd){
        let result = this.state.currentAdImages.map(individualCar =>(
          <div key={individualCar[0].id} className="d-flex justify-content-center">
            <React.Fragment key={individualCar[0].id}>
              <Col xs={12} md={12} lg={8} className="p-2">
                <div className="card h-100 shadow-sm p-2" >
                  <img src={individualCar[0].path} className="card-img-top" alt={individualCar[0].description}/>
                </div>
              </Col>
            </React.Fragment>
            
          </div>
          ))
        return result;
      }
      else {
        return this.state.vehiclesHomepage.map(individualCar =>(
          <React.Fragment key={individualCar[0].id}>
            <Col xs={12} md={6} lg={4} className="p-2">
              <div className="card h-100 shadow-sm" >
                <img src={individualCar[0].path} className="card-img-top" alt={individualCar[0].description}/>
                <div className="card-body">
                  <h5 className="card-title">{individualCar[0].description}</h5>
                  <div className="text-center my-4">
                    <Button className="btn btn-primary" onClick={() => goToAd(individualCar)}>View More Pics</Button> 
                  </div>
                </div>
              </div>
            </Col>
          </React.Fragment>
          ))
        }
      }


    return (
      <Container className="wrapper-inside">


            <Container>
              <Row>
                <ReturnToInventory/>
                <Display />
              </Row>
            </Container>

            <Container>
              <Row>
                <Footer/>
              </Row>
            </Container>
          </Container>
    )
  }
}
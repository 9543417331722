
import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

const header = () => {
    return (
      <Navbar bg="light" expand="lg" className="navbar-nav mx-auto">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/contact">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/login">
              <Nav.Link>Call Us Now!</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/login">
              <Nav.Link>Find us on Google Maps</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
}

export default header;
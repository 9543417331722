import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import './App.css';

import Home         from './components/Home';
import Contact      from './components/Contact';
import Google       from './components/Google';
import Header       from './components/Header';
import HomeBanner   from './components/HomeBanner';



function App() {

  return (
    <div className="wrapper-inside">
      <Header />
      <HomeBanner />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/inventory" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/google" element={<Google />} />

        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;

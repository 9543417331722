import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

export default class Google extends React.Component {
  

  componentDidMount() {
    window.location.href = "https://goo.gl/maps/pwy55Zh7gPV5RuwD9";
  }

  render() {
    return (
      <Container className="wrapper-inside">
            <Container className="wrapper-inside">
                
            </Container>

            <Container >
                <div className="p-5 mb-4 bg-light rounded-3" style={{background: 'transparent', backgroundImage: 'url("../../media/cars3.jpeg")', }}>
                  <Container>
                    <div className="text-shadow">
                    <h1 className="display-5 fw-bold jumbo-text">Business 83 Auto Sales </h1>
                    <p className="col-md-8 fs-4 jumbo-text">Used Auto Cars Serving The Rio Grande Valley of South Texas</p>
                    </div>
                  </Container>
                </div>
            </Container>

            <Container>
              <Row>
                <div className="d-flex justify-content-center tall" >
                  <Col md={8}>
                    <h1> Find us on Google!</h1>
                    <h5>We are now redirecting you to the our Google Maps listing so you can quickly find directions to us
                    and so you can call us.</h5>
                  </Col>
                </div>
              </Row>
            </Container>

            <Container>
              <Row>
                <Footer/>
              </Row>
            </Container>
          </Container>
    )
  }
}